<template>
  <div>
    <v-card min-height="959" class="mt-0 my-md-2  mx-auto" :class="$vuetify.breakpoint.mdAndUp ? 'card-radius' : ''"
      color="#C3FFF5" flat>
      <v-row class="mx-6 py-6" justify="space-between" align="center">
        <span class="text-h6">
          {{ $t("package.choosePackage") }}
        </span>
        <lang-swicther />
      </v-row>
      <v-card-text class="px-0 pb-12">
        <v-row class="mx-0 mx-md-4 px-md-12">
          <v-card width="100%" elevation="0" :class="$vuetify.breakpoint.mdAndUp ? 'card-radius' : ''" :ripple="false">
            <v-slide-group v-model="model" mandatory class="pa-0 pa-md-4" center-active show-arrows>
              <v-slide-item v-for="(item, i) in packagePlans" :key="i" v-slot="{ active, toggle }">
                <v-row class="my-6 justify-center" @click="toggle">
                  <v-card class="card-radius white mx-4" max-width="160" min-width="160" :ripple="false"
                  :class="{'secondary':active}"
                    :elevation="packageId === item._id ? '12' : '6'" @click="getPackageDetails(item)"
                    :min-height="active ? '180' : '150'" :max-height="active ? '180' : '150'">
                    <v-img v-if="item.image" :src="baseUrl + item.image" class="card-radius" 
                      :class="{ 'mt-n3 secondary': active }" :min-height="packageId === item._id ? '180' : '150'"
                      :max-height="packageId === item._id ? '180' : '150'" 
                      />
                    <v-img v-else :src="images[index]" class="card-radius"
                      :min-height="packageId === item._id ? '180' : '150'" />
                    <v-chip class="white black--text mt-n9 max-width-chip" :class="{'secondary':active}" :ripple="false">
                      {{ $i18n.locale === "en" ? item.titleEn : item.titleAr }}
                    </v-chip>
                  </v-card>
                </v-row>
              </v-slide-item>
            </v-slide-group>
          </v-card>
        </v-row>
        <v-row class="mt-12 mx-0 px-md-2  mx-md-12">
          <v-card flat width="100%" min-height="466" :class="$vuetify.breakpoint.smAndUp ? 'card-radius' : ''"
            color="#FAFAFA">
            <v-card-text>
              <v-row class="pa-0 pa-md-5">
                <v-col cols="12" sm="12" md="6">
                  <h1 class="subtitle-1 black--text">
                    {{
                      $i18n.locale === "en"
                        ? chosenPlan.titleEn || "Healthy Plan"
                        : chosenPlan.titleAr || "Healthy Plan"
                    }}
                  </h1>
                  <v-card min-height="158" flat class="secondary mt-5 card-radius">
                    <v-card-text>
                      <small>
                        {{
                          $i18n.locale === "en"
                            ? chosenPlan.descriptionEn
                            : chosenPlan.descriptionAr
                        }}
                      </small>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <h1 class="subtitle-1 black--text">
                    {{ $t("package.requiredDays") }}
                  </h1>
                  <v-card max-height="165" min-height="158" flat class="secondary mt-5 card-radius d-flex flex-nowrap overflow-x-auto overflow-y-hidden px-3 py-2 ">
                    <div v-for="(
                                  i, index
                                ) in chosenPlan.availableChoices" :key="index">
                                <v-card max-height="130" min-height="130" max-width="150" min-width="150"
                                  @click="selectedChoice(i)" :class="
                                    choiceId === i._id ? 'primary' : 'white'
                                  " class="mx-1 my-2 card-radius d-flex justify-center align-center pt-3 ">
                                  <v-card-text class="d-flex justify-center align-center px-0">

                                    <p :class="
                                      choiceId === i._id
                                        ? 'font-weight-bold'
                                        : ''
                                    ">
                                      <span class="d-flex justify-center align-center text-h4"> {{ i.availableNumOfDays }}</span>
                                      <v-spacer></v-spacer>
                                      <span>

                                      <div class="text-caption px-3 mt-2 " style="line-height:1.4" >
                                        {{
                                          $i18n.locale === "en"
                                            ? i.choiceNameEn
                                            : i.choiceNameAr
                                        }}</div>
                                      </span>
                                    </p>
                                  </v-card-text>
                                </v-card>
                              </div>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="selectedChoiceDetails">
                  <h1 class="subtitle-1 black--text mb-4">Plan Content</h1>
                  <div class="d-flex align-center" style="overflow-y:auto;">
                    <div v-for="( item, index) in selectedChoiceDetails.mealChoice" :key="index">
                      <v-card @click="selectMealChoiceVariant(item)"
                      :ripple="false"
                        :class="variantId === item._id ? 'secondary ' : 'white'" class="rounded-lg  me-10  mb-10"
                        max-width="450" elevation="3" min-width="450" min-height="81" max-height="181" outlined>
                        <v-card-text class="px-1 py-0  " >
                          <v-row class="px-2 " >
                            <v-col cols="4" class="d-flex justify-center align-center">
                              <div>
                                <v-img :src="
                                  require('../../assets/logo.png')
                                " width="80" contain />
                              </div>
                            </v-col>
                            <v-col cols="4" class="d-flex justify-center align-center flex-column">

                            </v-col>
                            <v-col cols="4"
                              class="primary text-center d-flex justify-center align-center rounded-r-lg ">
                              <div>
                                <!-- <h4 class="">{{ item.day }}</h4> -->
                                <MealSplitup :dayChoices="item.dayChoices" />
                                <h4 class="">
                                  {{ `${item.price}KD` }}
                                </h4>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" sm="4" class="ps-8">
                  <p class="black--text">{{ $t("package.startDate") }}</p>
                </v-col>
                <v-col cols="12" md="5" sm="8" class="mt-n2 ps-8 ">
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="startDate"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="startDate" outlined dense rounded hide-details=""
                        class="custom-field1 mx-auto white" append-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker :min="minDate" v-model="startDate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(startDate)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
        <v-layout justify-center>
          <p v-if="dateError" class="red--text text-center mt-6">
            {{ dateError }}
          </p>
        </v-layout>
        <v-row justify="center" class="mx-12">
          <v-btn depressed rounded color="primary custom-font" class="black--text font-weight-light mt-12"
            :width="$vuetify.breakpoint.smAndDown ? 250 : 315" @click="openConsultDialog">Proceed
            <v-icon right> mdi-arrow-right </v-icon>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="consultDialog" max-width="990" :fullscreen="$vuetify.breakpoint.smAndDown">
      <v-card :min-height="$vuetify.breakpoint.mdAndUp ? '600px' : '100%'" max-width="990"
        class="card-radius secondary pa-0">
        <v-card-title>
          <v-row class="justify-end px-5">
            <v-icon class="mt-4 black--text" @click="consultDialog = !consultDialog">
              mdi-close-circle
            </v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <Consult @proceedToPayment="proceedToPayment" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import MealSplitup from "./MealSplitup.vue";
export default {

  data() {
    return {
      model: false,
      consultDialog: false,
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      minDate: moment().add(3, "day").toDate().toISOString(),
      menu: false,
      packageId: null,
      btn_loading: false,
      chosenPlan: {},
      packagePlans: [],
      allDays: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      choiceId: null,
      variantId: null,
      selectedChoiceMeals: {},
      selectedChoiceDetails: {},
      lastReportDate: null,
      dateError: null,
      images: [
        require("../../assets/images/Plan_1.jpg"),
        require("../../assets/images/Plan_1.jpg"),
        require("../../assets/images/Plan 2.jpg"),
        require("../../assets/images/Plan_1.jpg"),
        require("../../assets/images/Plan_1.jpg"),
        require("../../assets/images/Plan_1.jpg"),
        require("../../assets/images/Plan_1.jpg"),
        require("../../assets/images/Plan 2.jpg"),
        require("../../assets/images/Plan_1.jpg"),
        require("../../assets/images/Plan_1.jpg"),
      ],
    };
  },
  components: {
    Consult: () => import("../../health/components/Consult.vue"),
    "lang-swicther": () => import("@/core/components/LangSwitch"),
    MealSplitup
  },
  apollo: {
    getAllPackages() {
      return {
        query: require("@/package/api/queries/get_all_packages.graphql"),
        result({ data }) {
          // , loading, networkStatus, refetch
          this.packagePlans = data.getAllPackages.packages;
          if (this.packagePlans.length)
            this.getPackageDetails(this.packagePlans[0]);
        },
      };
    },
    getLastReportGeneratedDate() {
      return {
        query: require("@/package/api/queries/last_report_generated_date.graphql"),
        result({ data }) {
          // , loading, networkStatus, refetch
          this.lastReportDate = data.getLastReportGeneratedDate;
        },
      };
    },
  },
  methods: {
    getPackageDetails(item) {
      this.packageId = item._id;
      this.$apollo
        .query({
          query: require("@/package/api/queries/single_package.graphql"),
          variables: {
            packageId: this.packageId,
          },
        })
        .then((data) => {
          this.chosenPlan = data.data.getPackageById;
          this.choiceId = this.chosenPlan.availableChoices[0]._id;
          this.selectedChoiceDetails = this.chosenPlan.availableChoices[0];
          this.selectedChoiceMeals =
            this.chosenPlan.availableChoices[0].mealChoice[0];
          this.variantId =
            this.chosenPlan.availableChoices[0].mealChoice[0]._id;

          this.selectedChoiceMeals.date = this.startDate;
          this.selectedChoiceMeals.choiceId = this.choiceId;
          this.$store.commit("MEAL_CHOICE", this.selectedChoiceMeals);
          this.$store.commit("CHOSEN_PLAN", this.chosenPlan);
        });
    },
    selectedChoice(item) {
      debugger
      this.choiceId = item._id;
      this.selectedChoiceDetails = item;
      this.selectedChoiceMeals = item.mealChoice[0];
      this.selectedChoiceMeals.choiceId = this.choiceId;

      this.selectedChoiceMeals = item.mealChoice[0];
      this.variantId = item.mealChoice[0]._id;

      this.$store.commit("MEAL_CHOICE", this.selectedChoiceMeals);
    },
    selectMealChoiceVariant(item) {
      this.selectedChoiceMeals = item;
      this.selectedChoiceMeals.choiceId = this.choiceId;
      this.variantId = item._id;
      this.$store.commit("MEAL_CHOICE", this.selectedChoiceMeals);
    },
    openConsultDialog() {
      this.consultDialog = true;
    },
    proceedToPayment(dietitianData) {
      if (dietitianData) {
        this.$store.commit("SET_DIETITIAN", dietitianData);
      }
      const lastReport = moment(moment(this.lastReportDate), "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      const newDate = moment(this.startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
      if (!moment(newDate).isAfter(lastReport)) {
        this.dateError = `Date should be greater than last reported date (${lastReport})`;
      } else this.$router.push("/payment");
    },
  },
};
</script>

<style lang="scss" scoped>
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}

.max-width-chip {
  min-width: 160px;
  max-width: 160px;
}

.custom-table {
  min-width: 250px;
}
</style>
