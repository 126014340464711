<template>
    <v-container>
        <v-btn color="success" @click="showDialogBox">Meal Count</v-btn>
        <a href="javascriptt:void(0)" @click="showDialogBox">Meal</a>
        <v-dialog v-model="showDialog" max-width="500" >
            <v-card class="pa-3 px-md-12 py-md-4 pb-md-8 rounded-xl">
                <v-row justify="end">
                    <v-btn color="primary" text @click="showDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-row>
                <v-row justify="center">
                    <v-card-title class="headline primary--text pt-0">Meal Count</v-card-title>
                </v-row>
                <div v-for="(item,index) in dayChoices" :key="item._id" class="">
                    <strong class="my-4"> {{ item.day }}</strong>
                    <v-spacer></v-spacer>
                        <v-row v-for="day in item.mealCount" :key="day._id" justify="space-between" class="mx-0 font-weight-thin">
                            <span> {{ day.category.nameEn }} </span>
                            <span> {{ day.count }} </span>
                        </v-row>
                    <v-divider class="my-2" v-if="index < dayChoices.length-1"></v-divider>
                </div>
            </v-card>
        </v-dialog>
    </v-container>
</template>


<script>
export default {
    components: {},
    props: {
        dayChoices: {
            require: true,
        },
    },
    methods: {

        showDialogBox() {
            this.showDialog = true;
        },
    },
    created() { },
    data() {
        return {
            showDialog: false,
        };
    },
};
</script>
